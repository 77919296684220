import { Flex, Text, Box } from '@sparrowengg/twigs-react';
import logo from './assets/Background JoysWorld.png'
import newYearCard from './assets/Group@3x.png'
import christmasCard from './assets/Group1@3x.png'
import joysWorld from './assets/Joys World@3x.png'
import adventurous from './assets/Adventurous.png'
import religious from './assets/Religious.png'
import scenic from './assets/Scenic.png' 
import tripToRemember from './assets/A TRIP TO REMEMBER@3x.png'
import christmas1 from './assets/christmas/christmas1.jpeg'
import christmas2 from "./assets/christmas/christmas2.jpeg";
import christmas3 from "./assets/christmas/christmas3.jpeg";
import christmas4 from "./assets/christmas/christmas4.jpeg";
import christmas5 from "./assets/christmas/christmas5.jpeg";
import { useEffect, useState } from 'react';


import './App.css';

function App() {
  const [images, setImages] = useState([
    christmas1,
    christmas2,
    christmas3,
    christmas4,
    christmas5,
  ]);

  const [currentImage, setCurrentImage] = useState(images[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => {
        const currentIndex = images.indexOf(prevImage);
        const nextIndex = (currentIndex + 1) % images.length;
        return images[nextIndex];
      });
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);
  return (
    <Flex
      flexDirection="column"
      css={{
        "@media (max-width: 767px)": {
          height: "fit-content !important",
        },
        flexGrow: 1,
        width: "100vw", // Full width of the viewport
        height: "100vh",
        backgroundImage: `url(${currentImage})`, // Set the background image
        backgroundSize: "cover", // Ensure the background covers the container
        backgroundPosition: "center", // Center the background image
        position: "relative",
      }}
    >
      <Box
        css={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay with 50% opacity
          zIndex: 0, // Ensures the overlay is behind the content
        }}
      />
      <Flex
        alignItems="center"
        justifyContent="space-between"
        css={{
          zIndex: 1, // Ensures the content is above the overlay
          padding: "$10",
        }}
      >
        <Flex alignItems="center">
          <img height={100} src={logo} alt="" />
          <img height={50} src={joysWorld} alt="" />
        </Flex>

        <Flex
          gap="$32"
          css={{
            "@media (max-width: 767px)": {
              // Mobile screens
              display: "none",
            },
          }}
        >
          <TypeItem image={adventurous} type="Adventurous" />
          <TypeItem image={religious} type="Religious" />
          <TypeItem image={scenic} type="Scenic" />
        </Flex>
      </Flex>
      <Box css={{
        "@media (min-width: 768px)": {
          display: 'none'
        },
        visibility: 'hidden'
      }}>
        <Route
          routes={[
            "Mumbai",
            "Goa",
            "Kochi",
            "Munnar",
            "Kanyakumari",
          ]}
        />
      </Box>
      <Flex
        flexDirection="column"
        css={{
          p: {
            margin: 0,
          },
          "@media (min-width: 768px)": {
            position: "absolute",
            bottom: 40,
            right: 0,

            padding: "$20",
          },

          zIndex: 1,
          padding: "$10",
          ".trip": {
            "@media (min-width: 768px)": {
              height: "80px !important",
            },
            "@media (max-width: 767px)": {
              height: "50px !important",
            },
          },
        }}
      >
        <img className="trip" src={tripToRemember} />
        <Flex
          alignItems="center"
          gap="$8"
          css={{
            "@media (min-width: 768px)": {
              flexDirection: "row",
            },
            flexDirection: "column",
          }}
        >
          <Flex
            onClick={() =>
              setImages([
                christmas1,
                christmas2,
                christmas3,
                christmas4,
                christmas5,
              ])
            }
            css={{}}
            gap="$2"
            flexDirection="column"
            alignItems="end"
          >
            <Text
              css={{
                color: "#E6F2F1",
                fontSize: "$xl",
              }}
            >
              CHRISTMAS EVE
            </Text>
            <Box
              css={{
                height: "2px",
                backgroundColor: "#E6F2F1",
                opacity: 0.5,
                width: "100%",
              }}
            />
            <img height={160} src={newYearCard} />
          </Flex>
          <Flex
            css={{
              filter: "grayscale(100%)",
              pointerEvent: "none",
            }}
            gap="$2"
            flexDirection="column"
            justifyContent="center"
            alignItems="end"
          >
            <Text
              css={{
                color: "#E6F2F1",
                fontSize: "$xl",
              }}
            >
              NEW YEAR'S EVE
            </Text>
            <Box
              css={{
                height: "2px",
                backgroundColor: "#E6F2F1",
                opacity: 0.5,
                width: "100%",
              }}
            />
            <img height={160} src={christmasCard} />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        flexDirection="column"
        gap="$4"
        css={{
          "@media (min-width: 768px)": {
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
          },
          "@media (max-width: 767px)": {
            zIndex: 2,
          },
          height: "140px",
          padding: "$4 $20",
          backgroundColor: "#E6F2F1",
          color: "#425554",
          p: {
            margin: 0,
          },
        }}
      >
        <Flex>
          <Text
            css={{
              fontSize: "$sm",
              textDecoration: "underline #53B0A7",
            }}
          >
            CONTACT US
          </Text>
        </Flex>
        <Flex gap="$4">
          <Box
            as="a"
            href="https://youtube.com/@joysdreamworld?si=2J2kKChpmgf02Gmr"
            target="_blank"
          >
            <YoutubeIcon />
          </Box>
          <Box
            as="a"
            href="https://www.instagram.com/joystechworld?igsh=MWt5Nm13Y2wzMDJqdQ%3D%3D&utm_source=qr"
            target="_blank"
          >
            <InstaIcon />
          </Box>
        </Flex>
        <Text
          css={{
            marginTop: "auto",
            fontSize: "10px",
          }}
        >
          2024 Joys World
        </Text>
      </Flex>
    </Flex>
  );
}

export default App;


const TypeItem = ({ type, image }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      css={{
        width: "250px",
        height: "100px",
        borderRadius: "$lg",
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        color: "$white900",
        position: "relative",
      }}
    >
      <Box
        css={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          borderRadius: "$lg",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.2)", // Black overlay with 50% opacity
          zIndex: 0, // Ensures the overlay is behind the content
        }}
      />
      <Text
        css={{
          zIndex: 1,
          fontSize: "$2xl",
          fontWeight: "$5",
        }}
      >
        {type}
      </Text>
    </Flex>
  );
}


const Route = ({ routes = [] }) => {

  return (
    <Flex alignItems="start" gap="$8" css={{
       '@media (max-width: 767px)': { 
        padding: '$12 !important',
       },
      padding: '$32',
      zIndex: 1,
    }} flexDirection="column">
      {routes.map((route) => (
        <RouteItem route={route} />
      ))}

    </Flex>
  )

}

const RouteItem = ({ route }) => {
  return (
    <Flex alignItems="center" gap="$4" css={{}}>
      <Box
        css={{
          border: route?.length ? "3px solid rgba(255, 255, 255, .2);" : "none",
          outline: route?.length ? "1px solid white" : 'none',
          marginLeft: route?.length ? "0px" : "3px",
          backgroundColor: "#C29E47",
          width: "14px",
          height: "14px",
          borderRadius: "$round",
        }}
      ></Box>
      <Text
        css={{
          margin: "0px",
          color: 'white',
          fontSize: '$lg'
        }}
      >
        {route}
      </Text>
    </Flex>
  );
};  


const YoutubeIcon = () => {
  return (
    <svg
      width="32px"
      height="32px"
      viewBox="0 -7 48 48"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Youtube-color</title>
      <desc>Created with Sketch.</desc>
      <defs></defs>
      <g
        id="Icons"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Color-"
          transform="translate(-200.000000, -368.000000)"
          fill="#CE1312"
        >
          <path
            d="M219.044,391.269916 L219.0425,377.687742 L232.0115,384.502244 L219.044,391.269916 Z M247.52,375.334163 C247.52,375.334163 247.0505,372.003199 245.612,370.536366 C243.7865,368.610299 241.7405,368.601235 240.803,368.489448 C234.086,368 224.0105,368 224.0105,368 L223.9895,368 C223.9895,368 213.914,368 207.197,368.489448 C206.258,368.601235 204.2135,368.610299 202.3865,370.536366 C200.948,372.003199 200.48,375.334163 200.48,375.334163 C200.48,375.334163 200,379.246723 200,383.157773 L200,386.82561 C200,390.73817 200.48,394.64922 200.48,394.64922 C200.48,394.64922 200.948,397.980184 202.3865,399.447016 C204.2135,401.373084 206.612,401.312658 207.68,401.513574 C211.52,401.885191 224,402 224,402 C224,402 234.086,401.984894 240.803,401.495446 C241.7405,401.382148 243.7865,401.373084 245.612,399.447016 C247.0505,397.980184 247.52,394.64922 247.52,394.64922 C247.52,394.64922 248,390.73817 248,386.82561 L248,383.157773 C248,379.246723 247.52,375.334163 247.52,375.334163 L247.52,375.334163 Z"
            id="Youtube"
          ></path>
        </g>
      </g>
    </svg>
  );
}

const InstaIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32px"
      height="32px"
      viewBox="0 0 102 102"
      id="instagram"
    >
      <defs>
        <radialGradient
          id="a"
          cx="6.601"
          cy="99.766"
          r="129.502"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".09" stop-color="#fa8f21"></stop>
          <stop offset=".78" stop-color="#d82d7e"></stop>
        </radialGradient>
        <radialGradient
          id="b"
          cx="70.652"
          cy="96.49"
          r="113.963"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".64" stop-color="#8c3aaa" stop-opacity="0"></stop>
          <stop offset="1" stop-color="#8c3aaa"></stop>
        </radialGradient>
      </defs>
      <path
        fill="url(#a)"
        d="M25.865,101.639A34.341,34.341,0,0,1,14.312,99.5a19.329,19.329,0,0,1-7.154-4.653A19.181,19.181,0,0,1,2.5,87.694,34.341,34.341,0,0,1,.364,76.142C.061,69.584,0,67.617,0,51s.067-18.577.361-25.14A34.534,34.534,0,0,1,2.5,14.312,19.4,19.4,0,0,1,7.154,7.154,19.206,19.206,0,0,1,14.309,2.5,34.341,34.341,0,0,1,25.862.361C32.422.061,34.392,0,51,0s18.577.067,25.14.361A34.534,34.534,0,0,1,87.691,2.5a19.254,19.254,0,0,1,7.154,4.653A19.267,19.267,0,0,1,99.5,14.309a34.341,34.341,0,0,1,2.14,11.553c.3,6.563.361,8.528.361,25.14s-.061,18.577-.361,25.14A34.5,34.5,0,0,1,99.5,87.694,20.6,20.6,0,0,1,87.691,99.5a34.342,34.342,0,0,1-11.553,2.14c-6.557.3-8.528.361-25.14.361s-18.577-.058-25.134-.361"
      ></path>
      <path
        fill="url(#b)"
        d="M25.865,101.639A34.341,34.341,0,0,1,14.312,99.5a19.329,19.329,0,0,1-7.154-4.653A19.181,19.181,0,0,1,2.5,87.694,34.341,34.341,0,0,1,.364,76.142C.061,69.584,0,67.617,0,51s.067-18.577.361-25.14A34.534,34.534,0,0,1,2.5,14.312,19.4,19.4,0,0,1,7.154,7.154,19.206,19.206,0,0,1,14.309,2.5,34.341,34.341,0,0,1,25.862.361C32.422.061,34.392,0,51,0s18.577.067,25.14.361A34.534,34.534,0,0,1,87.691,2.5a19.254,19.254,0,0,1,7.154,4.653A19.267,19.267,0,0,1,99.5,14.309a34.341,34.341,0,0,1,2.14,11.553c.3,6.563.361,8.528.361,25.14s-.061,18.577-.361,25.14A34.5,34.5,0,0,1,99.5,87.694,20.6,20.6,0,0,1,87.691,99.5a34.342,34.342,0,0,1-11.553,2.14c-6.557.3-8.528.361-25.14.361s-18.577-.058-25.134-.361"
      ></path>
      <path
        fill="#fff"
        d="M461.114,477.413a12.631,12.631,0,1,1,12.629,12.632,12.631,12.631,0,0,1-12.629-12.632m-6.829,0a19.458,19.458,0,1,0,19.458-19.458,19.457,19.457,0,0,0-19.458,19.458m35.139-20.229a4.547,4.547,0,1,0,4.549-4.545h0a4.549,4.549,0,0,0-4.547,4.545m-30.99,51.074a20.943,20.943,0,0,1-7.037-1.3,12.547,12.547,0,0,1-7.193-7.19,20.923,20.923,0,0,1-1.3-7.037c-.184-3.994-.22-5.194-.22-15.313s.04-11.316.22-15.314a21.082,21.082,0,0,1,1.3-7.037,12.54,12.54,0,0,1,7.193-7.193,20.924,20.924,0,0,1,7.037-1.3c3.994-.184,5.194-.22,15.309-.22s11.316.039,15.314.221a21.082,21.082,0,0,1,7.037,1.3,12.541,12.541,0,0,1,7.193,7.193,20.926,20.926,0,0,1,1.3,7.037c.184,4,.22,5.194.22,15.314s-.037,11.316-.22,15.314a21.023,21.023,0,0,1-1.3,7.037,12.547,12.547,0,0,1-7.193,7.19,20.925,20.925,0,0,1-7.037,1.3c-3.994.184-5.194.22-15.314.22s-11.316-.037-15.309-.22m-.314-68.509a27.786,27.786,0,0,0-9.2,1.76,19.373,19.373,0,0,0-11.083,11.083,27.794,27.794,0,0,0-1.76,9.2c-.187,4.04-.229,5.332-.229,15.623s.043,11.582.229,15.623a27.793,27.793,0,0,0,1.76,9.2,19.374,19.374,0,0,0,11.083,11.083,27.813,27.813,0,0,0,9.2,1.76c4.042.184,5.332.229,15.623.229s11.582-.043,15.623-.229a27.8,27.8,0,0,0,9.2-1.76,19.374,19.374,0,0,0,11.083-11.083,27.716,27.716,0,0,0,1.76-9.2c.184-4.043.226-5.332.226-15.623s-.043-11.582-.226-15.623a27.786,27.786,0,0,0-1.76-9.2,19.379,19.379,0,0,0-11.08-11.083,27.748,27.748,0,0,0-9.2-1.76c-4.041-.185-5.332-.229-15.621-.229s-11.583.043-15.626.229"
        transform="translate(-422.637 -426.196)"
      ></path>
    </svg>
  );
}